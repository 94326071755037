'use client'

import { useState } from 'react'
import { cn } from '~/utils/cn'
import { DefaultInfoIcon, Tooltip, TooltipProvider } from '../tooltip'
import { DropList } from './components'
import { CurrentIcon } from './components/drop-list/components/current-icon/current-icon'
import {
  containerBaseClassName,
  dropListButtonClassName,
  dropListContainerClassName,
  innerContainerBaseClassName,
  labelClassName,
  selectClassName,
} from './select.styles'
import { SelectKind, SelectProps, SelectSize, SelectVariant } from './select.types'

export const Select = ({
  label,
  error,
  disabled,
  containerClassName,
  innerContainerClassName,
  disabledLabel,
  collisionPadding,
  searchPlaceholder,
  size = SelectSize.large,
  tooltip,
  variant = SelectVariant.default,
  selectAction,
  isOptionDisabled,
  allowDeselect = false,
  ...props
}: SelectProps) => {
  const [isDropListOpen, setIsDropListOpen] = useState(false)

  const getSelectKind = (): SelectKind => {
    if (error) {
      return SelectKind.error
    }
    if ((!props?.multi && props?.value) || (props?.multi && props?.value?.length)) {
      return SelectKind.filled
    }
    if (disabled) {
      return SelectKind.disabled
    }
    if (Array.isArray(props.value) && props.value.length === 0) {
      return SelectKind.default
    }
    if (props.value) {
      return SelectKind.filled
    }
    return SelectKind.default
  }

  const className = selectClassName({
    size: props.multi ? SelectSize.variable : size,
    kind: getSelectKind(),
    variant,
    disabled,
  })

  return (
    <TooltipProvider>
      <div className={cn(containerBaseClassName({ variant }), containerClassName)}>
        <div className={cn(innerContainerBaseClassName({ variant }), innerContainerClassName)}>
          {label && (
            <span className={labelClassName({ variant, disabled })}>
              {label}

              {tooltip && (
                <Tooltip
                  content={tooltip}
                  tooltipTrigger={<DefaultInfoIcon className='ml-1' />}
                />
              )}
            </span>
          )}

          <div
            data-testid={props.name!}
            className={dropListContainerClassName({ variant })}
          >
            <DropList
              {...props}
              size={size}
              aria-label={label}
              searchPlaceholder={searchPlaceholder}
              className={cn(dropListButtonClassName({ variant }), className)}
              icon={
                <CurrentIcon
                  name={props.name!}
                  setValue={props.setValue}
                  value={props.value}
                  variant={variant}
                  isDropListOpen={isDropListOpen}
                />
              }
              disabled={disabled}
              disabledLabel={disabledLabel}
              collisionPadding={collisionPadding}
              variant={variant}
              setIsDropListOpen={setIsDropListOpen}
              isOptionDisabled={isOptionDisabled}
              selectAction={selectAction}
              allowDeselect={allowDeselect}
            />
          </div>

          {error && <span className='mt-1 flex items-center text-xs/6 text-status-critical'>{error}</span>}
        </div>
      </div>
    </TooltipProvider>
  )
}

import { tv } from 'tailwind-variants'
import { cn } from '~/utils/cn'
import { SelectKind, SelectSize, SelectVariant } from './select.types'

export const selectClassName = tv({
  base: cn(
    'bg-neutral-00 hover:bg-neutral-10',
    'rounded-xl border border-solid',
    'text-sm/6 font-normal text-neutral-tertiary',
    'gap-4 px-4',
    'focus-within:border-brand-primary-rest focus:border-brand-primary-rest',
    'data-[is-open=true]:border-brand-primary-rest',
  ),
  variants: {
    kind: {
      [SelectKind.default]: 'border-bg-neutral-10',
      [SelectKind.error]: 'border-status-critical text-neutral-primary',
      [SelectKind.filled]: 'text-neutral-primary',
      [SelectKind.disabled]: 'border-neutral-disabled bg-neutral-00 text-neutral-disabled',
    },
    size: {
      [SelectSize.large]: 'h-10 py-2',
      [SelectSize.medium]: 'py-1.5',
      [SelectSize.small]: 'h-8 py-1',
      [SelectSize.xsmall]: 'h-6 py-1',
      [SelectSize.variable]: 'h-auto min-h-10 py-2',
    },
    variant: {
      [SelectVariant.default]: '',
      [SelectVariant.inline]: 'min-w-[200px] border-none bg-transparent p-0 hover:bg-transparent',
      [SelectVariant.filter]: '',
      [SelectVariant.withIcon]: '',
    },
    disabled: {
      true: 'border-neutral-disabled',
    },
  },
  compoundVariants: [
    {
      loading: false,
      className: 'hover:bg-neutral-10 focus:border-brand-primary-rest',
    },
    {
      variant: SelectVariant.inline,
      className: 'focus:border-transparent',
    },
  ],
})

export const iconClassName = tv({
  base: 'fa-light fa-chevron-down text-sm',
  variants: {
    variant: {
      [SelectVariant.default]: '',
      [SelectVariant.inline]: '',
      [SelectVariant.filter]: 'text-icon-neutral-20 transition-transform data-[is-open=true]:-rotate-180',
      [SelectVariant.withIcon]: '',
    },
  },
})

export const containerBaseClassName = tv({
  base: 'w-full',
  variants: {
    variant: {
      [SelectVariant.default]: '',
      [SelectVariant.inline]: 'inline-flex items-center',
      [SelectVariant.filter]: 'inline-flex w-auto items-center',
      [SelectVariant.withIcon]: 'inline-flex items-center',
    },
  },
})

export const innerContainerBaseClassName = tv({
  base: 'flex w-full flex-col text-neutral-primary',
  variants: {
    variant: {
      [SelectVariant.default]: '',
      [SelectVariant.inline]: 'flex-row items-center gap-2',
      [SelectVariant.filter]: 'flex-row items-center gap-2',
      [SelectVariant.withIcon]: '',
    },
  },
})

export const labelClassName = tv({
  base: 'text-sm/6 font-normal text-neutral-primary',
  variants: {
    variant: {
      [SelectVariant.default]: '',
      [SelectVariant.inline]: 'flex items-center whitespace-nowrap',
      [SelectVariant.filter]: 'flex items-center whitespace-nowrap',
      [SelectVariant.withIcon]: 'inline-flex items-center',
    },
    disabled: {
      true: 'text-neutral-disabled',
    },
  },
})

export const dropListContainerClassName = tv({
  base: 'relative',
  variants: {
    variant: {
      [SelectVariant.default]: '',
      [SelectVariant.inline]: 'flex-grow',
      [SelectVariant.filter]: 'w-max flex-grow',
      [SelectVariant.withIcon]: 'w-full flex-grow',
    },
  },
})

export const dropListButtonClassName = tv({
  variants: {
    size: {
      [SelectSize.xsmall]: 'text-xs/6',
    },
    variant: {
      [SelectVariant.default]: '',
      [SelectVariant.inline]: '',
      [SelectVariant.filter]: '!border-transparent',
      [SelectVariant.withIcon]: '',
    },
  },
})
